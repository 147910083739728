import { i18n } from "@/locale";
import store from "@/store";
const { t: $t } = i18n.global;

export default [
    {
        path: "/oem",
        name: "oem__root__",
        redirect: {
            name: "oem-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "oem",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "oem-index",
                component: () => import("@/views/oem/index.vue"),
                meta: {
                    title: $t("nav.control"),
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/oem/order",
        name: "oem-order",
        redirect: "/oem/order/mine",
        component: () => import("@/layouts/default.vue"),
        beforeEnter: (to, from, next) => {
            store.dispatch("goods/loadCategories");
            next();
        },
        meta: {
            title: $t("nav.oem.order_management.name"),
            parent: "oem",
            icon: "workflows-setting",
            permission: "oem_customer",
        },
        children: [
            {
                path: "create",
                name: "oem-order-create",
                component: () => import("@/views/oem/create.vue"),
                meta: {
                    title: $t("nav.oem.order_management.new"),
                    icon: "place-order",
                },
            },
            {
                path: ":id(\\d+)/edit",
                name: "oem-order-edit",
                component: () => import("@/views/oem/edit.vue"),
                hidden: true,
                meta: {
                    title: $t("nav.oem.order_management.edit"),
                    activeMenu: "/oem/order/mine",
                },
            },
            {
                path: ":id(\\d+)",
                name: "oem-order-detail",
                component: () => import("@/views/oem/detail.vue"),
                hidden: true,
                meta: {
                    title: $t("nav.oem.order_management.detail"),
                    activeMenu: "/oem/order/mine",
                },
            },
            {
                path: "mine",
                name: "oem-order-mine",
                component: () => import("@/views/oem/mine.vue"),
                meta: {
                    title: $t("nav.oem.order_management.mine"),
                    icon: "my-order",
                },
            },
            {
                path: "bill",
                name: "oem-order-bill",
                component: () => import("@/views/fms/bill/oem-card-log.vue"),
                meta: {
                    title: $t("nav.oem.order_management.bill"),
                    icon: "transfer",
                    mine: true,
                },
            },
        ],
    },
    {
        path: "/oem/inventory",
        name: "oem-inventory",
        redirect: "/oem/inventory",
        component: () => import("@/layouts/default.vue"),
        meta: {
            title: $t("nav.oem.inventory.name"),
            parent: "oem",
            icon: "inventory",
        },
        children: [
            {
                path: "main",
                name: "oem-inventory-main",
                component: () => import("@/views/fms/produce/oem_inventory/index.vue"),
                meta: {
                    title: $t("nav.oem.inventory.mine"),
                    icon: "my-inventory",
                    mine: true,
                },
            },
        ],
    },
];
