export default {
    home: "Home",
    manage: "Admin",
    control: "Control Panel",
    common: {
        account: {
            name: "Account",

            profile: "Profile",
            message: "Message",
            address: "Address",
            wechat_bind: "Wechat Bind",
        },
    },
    oem: {
        // 订单管理组
        order_management: {
            name: "Orders",

            new: "New Order",
            mine: "My Orders",
            edit: "Edit Order",
            detail: "Order Detail",
            bill: "My Transfer",
        },
        inventory: {
            name: "Inventory",
            mine: "My Inventory",
            adjust: "Adjust Inventory",
        },
    },
};
