import { useCount } from "@/utils/count";

const load = async () => {
    await useCount().countWww();
};

export default [
    {
        path: "/cms",
        name: "cms__root__",
        redirect: {
            name: "cms-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            isPublic: true,
            parent: "cms",
            title: "首页",
        },
        beforeEnter: async (to, from, next) => {
            await load();
            next();
        },
        children: [
            {
                path: "index",
                name: "cms-index",
                component: () => import("@/views/cms/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/cms/misc",
        name: "cms-misc",
        meta: {
            title: "全局设置",
            icon: "atom",
            parent: "cms",
            permission: "manage_global_config",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/cms/misc/announcement",
        children: [
            {
                path: "titanconf",
                name: "dev-titanconf",
                component: () => import("@/views/omp/titan/titanconf"),
                meta: {
                    title: "基础配置",
                    icon: "titanconf",
                    affix: false,
                },
            },
            {
                path: "announcement",
                name: "announcement",
                component: () => import("@/views/cms/misc/announcement"),
                meta: {
                    title: "公告管理",
                    icon: "announcement",
                    affix: false,
                },
            },
            {
                path: "wechat/menu",
                name: "wechat-menu",
                component: () => import("@/views/omp/system/wx_menu.vue"),
                meta: {
                    title: "微信菜单",
                    icon: "wx",
                },
            },
            {
                path: "dashboard",
                name: "dashboard",
                component: () => import("@/views/cms/misc/dashboard"),
                meta: {
                    title: "后台导航",
                    icon: "application",
                    affix: false,
                },
            },
            {
                path: "menu",
                name: "cms-menu",
                component: () => import("@/views/cms/misc/menu/index.vue"),
                meta: {
                    title: "菜单管理",
                    icon: "menu2",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/cms/mstpl",
        name: "mstpl",
        meta: {
            title: "消息模板",
            icon: "template",
            parent: "cms",
            permission: "manage_msg_template",
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/cms/mstpl/email",
        children: [
            {
                path: "email",
                name: "mstplEmail",
                component: () => import("@/views/cms/mstpl/email.vue"),
                meta: {
                    title: "邮件模板",
                    icon: "template-email",
                    affix: false,
                },
            },
            {
                path: "email-detail/:slug([\\w_-]+)?",
                name: "mstplEmailDetail",
                hidden: true,
                component: () => import("@/views/cms/mstpl/email/detail.vue"),
                meta: {
                    title: "邮件模板详情",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/cms/product",
        name: "cms-product",
        meta: {
            title: "产品中心",
            icon: "product",
            parent: "cms",
            permission: "manage_cms_product",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "productionList",
        },
        children: [
            {
                path: "types",
                name: "productionTypes",
                component: () => import("@/views/cms/product/productionTypes"),
                meta: {
                    title: "产品类型",
                    icon: "types",
                    affix: false,
                },
            },
            {
                path: "list",
                name: "productionList",
                component: () => import("@/views/cms/product/productionList"),
                meta: {
                    title: "产品管理",
                    icon: "ai",
                    affix: false,
                    keepAlive: true,
                },
            },
            {
                path: "view/:id(\\d+)?",
                name: "product",
                hidden: true,
                component: () => import("@/views/cms/product/product.vue"),
                meta: {
                    title: "产品内容",
                    icon: "ai",
                    affix: false,
                },
            },
        ],
    },
    // {
    //     path: "/cms/service",
    //     name: "cms-service",
    //     meta: {
    //         title: "服务中心",
    //         icon: "headset",
    //         parent: "cms",
    //         permission: "cms_manage_contact",
    //     },
    //     component: () => import("@/layouts/default.vue"),
    //     redirect: {
    //         name: "contact",
    //     },
    //     beforeEnter: async (to, from, next) => {
    //         await load();
    //         next();
    //     },
    //     children: [

    //     ],
    // },
    {
        path: "/omp/msg",
        name: "omp-msg",
        meta: {
            title: "消息中心",
            icon: "message",
            parent: "cms",
            permission: "manage_msg",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "omp-msg",
        },
        children: [
            {
                path: "message",
                name: "omp-msg-message",
                component: () => import("@/views/omp/msg/message/index.vue"),
                meta: {
                    title: "私信管理",
                    icon: "private",
                },
            },
            {
                path: "email",
                name: "omp-msg-email",
                component: () => import("@/views/omp/msg/email/index.vue"),
                meta: {
                    title: "邮件管理",
                    icon: "email",
                },
            },

            {
                path: "email/detail",
                name: "omp-msg-email-detail",
                hidden: true,
                component: () => import("@/views/omp/msg/email/email_detail"),
                meta: {
                    title: "发送邮件",
                    affix: false,
                },
            },
            {
                path: "wxmsg",
                name: "omp-msg-wxmsg",
                component: () => import("@/views/omp/msg/wxmsg/index.vue"),
                meta: {
                    title: "微信管理",
                    icon: "wxmsg",
                },
            },
            {
                path: "phmsg",
                name: "omp-msg-phmsg",
                component: () => import("@/views/omp/msg/phmsg/index.vue"),
                meta: {
                    title: "短信管理",
                    icon: "phmsg",
                },
            },
        ],
    },
    {
        path: "/cms/content",
        name: "cms-content",
        meta: {
            title: "内容中心",
            icon: "www",
            parent: "cms",
            permission: "manage_content",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "page",
        },
        children: [
            {
                path: "page",
                name: "page",
                component: () => import("@/views/cms/content/page"),
                meta: {
                    title: "页面管理",
                    icon: "page",
                    affix: false,
                },
            },
            {
                path: "page/:id(\\d+)+",
                name: "pageDetail",
                hidden: true,
                component: () => import("@/views/cms/content/detail"),
                meta: {
                    title: "页面详情",
                    affix: false,
                },
            },
            {
                path: "/page/:pageId(\\d+)+/meta/:metaId(\\d+)?",
                name: "metaDetail",
                hidden: true,
                component: () => import("@/views/cms/content/meta"),
                meta: {
                    title: "属性详情",
                    affix: false,
                },
            },
            {
                path: "block",
                name: "block",
                component: () => import("@/views/cms/content/block"),
                meta: {
                    title: "模块管理",
                    icon: "block",
                    affix: false,
                },
            },
            {
                path: "block/:id(\\d+)+",
                name: "blockDetail",
                hidden: true,
                component: () => import("@/views/cms/content/block_detail"),
                meta: {
                    title: "模块详情",
                    affix: false,
                },
            },
            {
                path: "attachment",
                name: "attachment",
                component: () => import("@/views/cms/content/attachment"),
                meta: {
                    title: "附件管理",
                    icon: "attachment",
                    affix: false,
                    keepAlive: true,
                },
            },
            {
                path: "article",
                name: "cms-content-article",
                component: () => import("@/views/cms/content/article"),
                meta: {
                    title: "文章管理",
                    icon: "article",
                    affix: false,
                    keepAlive: false,
                },
            },
            {
                path: "article-form/:id(\\d+)?",
                name: "cms-content-article-form",
                hidden: true,
                component: () => import("@/views/cms/content/article_form"),
                meta: {
                    title: "文章内容",
                    icon: "article",
                    affix: false,
                    activeMenu: "/cms/content/article",
                },
            },
        ],
    },
    {
        path: "/cms/document",
        name: "cms-document",
        meta: {
            title: "文档中心",
            icon: "document",
            parent: "cms",
            permission: "manage_documentation",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "documentCommonList",
        },
        children: [
            {
                path: "common",
                name: "documentCommonList",
                component: () => import("@/views/cms/source/document"),
                meta: {
                    title: "公共文档",
                    icon: "todo",
                    affix: false,
                    type: "common",
                },
            },
            {
                path: "titan",
                name: "documentTitanList",
                component: () => import("@/views/cms/source/document"),
                meta: {
                    title: "泰坦文档",
                    icon: "ai",
                    affix: false,
                    type: "titan",
                },
            },
            {
                path: "device",
                name: "documentDeviceList",
                component: () => import("@/views/cms/source/document"),
                meta: {
                    title: "设备文档",
                    icon: "device",
                    affix: false,
                    type: "device",
                },
            },
            {
                path: "detail/:id(\\d+)?",
                name: "document",
                hidden: true,
                component: () => import("@/views/cms/source/document/document_form2.vue"),
                meta: {
                    title: "文档内容",
                    icon: "document",
                    affix: false,
                },
            },
            {
                path: "guide",
                name: "guide",
                component: () => import("@/views/cms/source/guide"),
                meta: {
                    title: "标准指引",
                    icon: "guide",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/cms/resource",
        name: "cms-resource",
        component: () => import("@/layouts/default.vue"),
        redirect: "/cms/resource/file",
        meta: {
            title: "资源中心",
            icon: "resource",
            parent: "cms",
            permission: "manage_resource",
        },
        children: [
            // {
            //     path: "index",
            //     name: "resource",
            //     component: () => import("@/views/cms/resource/index"),
            //     meta: {
            //         title: "文件管理",
            //         icon: "oss",
            //         affix: false,
            //     },
            // },
            {
                path: "file",
                name: "cms-resource-file",
                component: () => import("@/views/cms/oss/file-mng.vue"),
                meta: {
                    title: "文件管理",
                    icon: "oss",
                    affix: false,
                },
            },
            {
                path: "aigrow-icons",
                name: "aigrow-icons",
                component: () => import("@/views/omp/aigrow/icons"),
                meta: {
                    title: "相册图标",
                    icon: "icons",
                    affix: false,
                },
            },
            {
                path: "sensor-icons",
                name: "sensor-icons",
                component: () => import("@/views/omp/sensor/icons"),
                meta: {
                    title: "属性图标",
                    icon: "icons",
                    affix: false,
                },
            },
        ],
    },
];
