export default {
    title: "OEM代工平台",

    status: {
        all: "全部",
        draft: "订单待确认",
        draft_complete: "订单已确认",
        unpaid: "支付待确认",
        unpaid_complete: "已支付",
        arrange: "安排生产中",
        arrange_complete: "生产已安排",
        production: "生产中",
        production_complete: "生产完成",
        shipping: "运输中",
        shipping_complete: "已收货",
        final_payment: "尾款待确认",
        final_payment_complete: "款项结清",
        completed: "订单已完成",
    },

    common: {
        add_button: "添加",
        confirm_button: "确认",
        remove_button: "移除",
        cancel_button: "取消",

        custom: "定制",
        removed: "移除",
        add: "增加",
        sub: "减少",

        official_files: "官方文件",
        produce_plan: "版本清单",
        category: "分类",
        product: "产品",
        version: "版本",
        sn: "编码",
        quantity: "数量",
        parts: "零配件",
        accessories: "附件",
        unit_use: "单位用量",
        total_use: "总计用量",
        unit_price: "单价",
        total_price: "总价",
        design_files: "工艺文件",
        remark: "备注",

        empty: "-",

        op: "操作",
        view: "查看",
    },

    create: {
        address: {
            label: "选择地址",
            empty_tip: "暂无地址，请前往 ",
            exist_tip: "需要新的地址？前往 ",
            add_button: "添加地址",

            empty_error: "请选择地址",
            title: "邮寄地址",
        },
        type: {
            label: "选择下单类型",
            part: "零件下单",
            product: "产品下单",
        },

        list: {
            add: "添加产品",
            label: "选择产品",
            version_tip: "请选择版本",
            version_empty_tip: "该产品无版本配置",
            version_new: "最新版本",

            parts_name: "请选择零配件",
            empty_error: "请选择产品以及版本号",
            parts_empty_tip: "请先选择产品以及版本，直接",
            accessories_empty_tip: "未选择附件，点击",

            custom_tip: "该零配件不是产品生产清单内的零配件，但是您为定制方案添加了它。",
            remove_tip: "产品生产清单内的零配件，但是在您的定制方案中已被移除。",
            quantity_change_tip: "生产需要数量: {plan_num}，定制数量: {num}",
        },

        success: "订单创建成功",
        confirm: "确认下单",
    },

    detail: {
        remark_button: "补充说明",
        remark_tip: "请输入 补充说明 内容",

        order_info: "订单信息",

        create_time: "创建时间",
        total_price: "订单总金额",
        receiver_name: "收件人",
        receiver_phone: "联系电话",
        receiver_address: "收货地址",
        product_list: "产品列表",
        address_empty_tip: "请填写完整收货信息",

        confirm_tip: "请先确认订单以进行后续流程",
        confirm_alert: "确认订单后将无法修改订单信息，是否继续？",

        vouchers_tip: "已上传 {count} 份付款凭证, 等待审核中",
        vouchers_empty_tip: "请上传付款凭证，以便财务确认款项",
        paid_tip: "已付款，正在安排生产任务",
        producing_tip: "正在生产中、可以在右侧查看生产任务进度",
        deliver_tip: "货物已发出，可以在右侧查看快递单号以查看进度",
        received_tip: "货物已签收，请付尾款",
        received_alert: "请确认货物已经收到，是否继续？",

        finished_tip: "订单已完成",

        go_pay: "前往付款",
        transnational_transfer: "跨国转账",
        transfer_create_success: "转账订单创建成功",
        received_button: "确认收货",

        extra_op: "补充操作",
    },

    list: {
        add: "新增订单",
        content: "订单内容",
        order_status: "订单状态",
        receive_info: "收货信息",
        create_time: "下单时间",

        delete_tip: "确认删除该订单？",
    },

    log: {
        label: "订单进度",
        express: "物流",
        produce_progress: "生产进度",
        produce_finished: "已完成",
        produce_doing: "进行中",
        copy_success: "复制成功！",

        create_order: "创建订单",
        confirm_order: "确认订单",
        order_express_send: "订单发货",
        start_produce: "开始生产",
        settle: "订单款项结清",
        receipt: "订单收款",
        refund: "订单退款",
        express_receipt: "确认收货",
        order_complete: "完成订单",
        add_remark: "补充说明",
        into_inventory: "进入库存",
    },

    vouchers: {
        dialog_title: "提交付款凭证",
        add: "提交付款凭证",
        manage: "管理付款凭证",
    },
};
