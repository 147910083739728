import { $cms } from "@/utils/https.js";

// 创建售后工单
export function createAfterSaleBucket(data) {
    return $cms().post(`/api/cms/after_sales/bucket`, data);
}

// 获取工单列表
export function getAfterSalesBuckets(params) {
    return $cms().get(`/api/cms/after_sales/bucket`, { params });
}

// 获取node历史工单列表
export function getAfterSalesBucketLogs(node_id) {
    return $cms().get(`/api/cms/after_sales/bucket/node/${node_id}/log`);
}

// 获取工单统计分析
export function getAfterSalesBucketStat(params) {
    return $cms().get(`/api/cms/after_sales/bucket/stat`, { params });
}

// 获取工单详情
export function getAfterSalesBucket(id) {
    return $cms().get(`/api/cms/after_sales/bucket/${id}`);
}

// 更新工单
export function updateAfterSalesBucket(id, data) {
    return $cms().put(`/api/cms/after_sales/bucket/${id}`, data);
}

// 更新工单类型
export function updateAfterSalesBucketType(id, data) {
    return $cms().put(`/api/cms/after_sales/bucket/${id}/type`, data);
}

// 设置工单外勤状态
export function updateAfterSalesBucketIsOut(id, data) {
    return $cms().put(`/api/cms/after_sales/bucket/${id}/is_out`, data);
}

// 删除工单
export function deleteAfterSalesBucket(id) {
    return $cms().delete(`/api/cms/after_sales/bucket/${id}`);
}

// 指派工单
export function assignAfterSalesBucket(id, data) {
    return $cms().put(`/api/cms/after_sales/bucket/${id}/assign`, data);
}

// 移出指派
export function rmAssignAfterSalesBucket(id, data) {
    return $cms().put(`/api/cms/after_sales/bucket/${id}/rm_assign`, data);
}

// 接单
export function acceptAfterSalesBucket(id) {
    return $cms().put(`/api/cms/after_sales/bucket/${id}/accept`);
}

// 关闭工单
export function closeAfterSalesBucket(id) {
    return $cms().put(`/api/cms/after_sales/bucket/${id}/close`);
}

// 提醒
export function remindAfterSalesBucket(id) {
    return $cms().get(`/api/cms/after_sales/bucket/${id}/alert`);
}

// 更新工单描述
export function updateAfterSalesBucketDesc(id, data) {
    return $cms().put(`/api/cms/after_sales/bucket/${id}/desc`, data);
}

// 完成工单
export function finishAfterSalesBucket(id) {
    return $cms().put(`/api/cms/after_sales/bucket/${id}/finish`);
}

// 获取工单列表统计
export function getAfterSalesBucketCount(params) {
    return $cms().get(`/api/cms/after_sales/bucket/count`, { params });
}

// 获取我的工单列表
export function getMyAfterSalesBuckets(params) {
    return $cms().get(`/api/cms/after_sales/bucket/i`, { params });
}

// 获取待处理工单列表
export function getPendingAfterSalesBuckets(params) {
    return $cms().get(`/api/cms/after_sales/bucket/pending`, { params });
}

// 获取待处理工单统计
export function getPendingAfterSalesBucketCount(params) {
    return $cms().get(`/api/cms/after_sales/bucket/pending/count`, { params });
}

// 流程
// 获取工单流程列表
export function getWorkflowProcessList(params) {
    return $cms().get(`/api/cms/after_sales/process`, { params });
}

// 创建工单流程
export function createWorkflowProcess(data) {
    return $cms().post(`/api/cms/after_sales/process`, data);
}

// 获取工单流程详情
export function getWorkflowProcessDetail(id) {
    return $cms().get(`/api/cms/after_sales/process/${id}`);
}

// 更新工单流程
export function updateWorkflowProcess(id, data) {
    return $cms().put(`/api/cms/after_sales/process/${id}`, data);
}

// 删除工单流程
export function deleteWorkflowProcess(id) {
    return $cms().delete(`/api/cms/after_sales/process/${id}`);
}
